<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-form>
        <!-- Title -->
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <v-container>
            <v-row>
              <!-- Name -->
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  :label="$t('projects.dialog.name-label')"
                  :error-messages="validationErrors['name']"
                  :error-count="5"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="isSaving"
          >
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    project: { type: Object, default: null }
  },
  emits: ['itemCreated', 'itemUpdated'],
  data () {
    return {
      name: '',
      isSaving: false,
      validationErrors: {}
    }
  },
  computed: {
    isNew () {
      return this.project == null
    },
    formTitle () {
      return this.$t(this.isNew ? 'projects.dialog.create-title' : 'projects.dialog.edit-title')
    },
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    close () {
      this.showDialog = false
    },
    async save () {
      this.isSaving = true
      this.validationErrors = {}

      try {
        // Send a request to create/update the item.
        const response = this.isNew ? await this.createNew() : await this.updateExisiting()
        // Notify the dialog has saved the item.
        const event = this.isNew ? 'itemCreated' : 'itemUpdated'
        this.$emit(event, response.data)
        // Show a message indicating the save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.project') })],
          duration: 5000
        })
        // Close the dialog.
        this.close()
      } catch (error) {
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('common.save-failed-message', { entityName: this.$t('common.project') })],
            duration: 5000
          })
        }
        console.error(error)
      } finally {
        this.isSaving = false
      }
    },
    async updateExisiting () {
      const payload = {
        id: this.project.id,
        name: this.name,
        systemIds: this.project.systemIds
      }
      return await axios.put('projects', payload)
    },
    async createNew () {
      const payload = {
        name: this.name,
        systemIds: []
      }
      return await axios.post('projects', payload)
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        // Skip update the values if the dialog is being opened.
        if (newVal) {
          this.name = this.project?.name ? this.project.name : ''
          this.validationErrors = {}
        }
      }
    }
  }
}
</script>
