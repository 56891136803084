<template>
  <ProjectsTable
    :items="projects"
    :total-size="totalSize"
    :page-size="pageSize"
    :page-number="pageNumber"
    :loading="isLoading"
    :features="features"
    :title="$t('projects.table.title')"
    @pageChanged="pageChanged"
    @itemCreated="onItemCreated"
    @itemUpdated="onItemUpdated"
    @itemDeleted="onItemDeleted"
    @sortingChanged="onSortingChanged"
    @refreshRequested="onRefreshRequested"
  />
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import ProjectsTable from '@/components/tables/ProjectsTable'

export default {
  components: { ProjectsTable },
  data () {
    return {
      projects: [],
      isLoading: true,
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isOverlord']),
    features () {
      return this.isOverlord ? ['create', 'update', 'delete'] : ['create', 'update']
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('projects', ['fetchProjects']),
    onItemUpdated (updatedProject) {
      // Try to find the updated project among the currently shown projects.
      const index = this.projects.findIndex((project) => project.id === updatedProject.id)
      if (index !== -1) {
        // Update the project to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.projects, index, updatedProject)
      }
    },
    async onItemDeleted (deletedProject) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onItemCreated (newProject) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, sorting) {
      await this.fetchPage(pageNumber, pageSize, sorting)
    }, 250),
    async fetchPage (pageNumber, pageSize, sorting) {
      this.isLoading = true
      try {
        const pagedResponse = await this.fetchProjects({ pageNumber, pageSize, sorting })
        this.projects = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestSorting)
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
